import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { css } from "@emotion/core"

// import { doc } from "prettier"

export default class MailchimpForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { email: "", first_name: "" }

    this._handleSubmit = this._handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  _handleSubmit = e => {
    e.preventDefault()
    const listFields = { FNAME: this.state.first_name }
    addToMailchimp(this.state.email, listFields)
      .then(data => {
        const signUpForm = document.querySelector(".form-container form")
        signUpForm.style.display = "none"

        const formContainer = document.querySelector(".form-container")
        const paragraph1 = document.createElement("p")
        paragraph1.innerText = "Thanks for signing up!"
        paragraph1.style.color = "red"
        paragraph1.style.fontSize = "25px"
        paragraph1.style.fontWeight = "bold"
        const paragraph2 = document.createElement("p")
        paragraph2.innerText =
          "Please check your email to confirm your subscription."
        paragraph2.style.fontWeight = "bold"
        formContainer.appendChild(paragraph1)
        formContainer.appendChild(paragraph2)
        console.log("SUCCESS")
      })
      .catch(() => {
        console.log("error")
      })
  }

  render() {
    return (
      <div className="form-container" style={{ color: `black` }}>
        <form onSubmit={this._handleSubmit}>
          <div className="form-example">
            <h3>Sign up for my Programming Thoughts newsletter:</h3>
            <div>
                <label
                    for="name"
                    css={css`
                        display: inline-block;
                        text-align: left;
                        width: 120px;
                    `}
                >
                First name: &nbsp;
                </label>
                <input
                    css={css`
                        margin: 0;
                    `}
                    type="text"
                    name="first_name"
                    value={this.state.value}
                    onChange={this.handleInputChange}
                    id="first_name"
                    required
                />
            </div>
            <div
                css={css`
                    display: block;
                `}
            >
                <label
                    for="name"
                    css={css`
                        display: inline-block;
                        text-align: left;
                        width: 120px;
                    `}
                >
                Email: &nbsp;
                </label>
                <input
                    css={css`
                        margin: 0;
                    `}
                    type="text"
                    name="email"
                    value={this.state.value}
                    onChange={this.handleInputChange}
                    id="email"
                    required
                />
            </div>

            <div className="form-example">
              <input
                type="submit"
                value="Submit!"
              />
            </div>
          </div>
        </form>
      </div>
    )
  }
}
